import classNames from 'classnames';

export enum IconName {
  AddressBook = 'address-book',
  AddressCard = 'address-card',
  Airplay = 'airplay',
  AirpodsAlt = 'airpods-alt',
  Airpods = 'airpods',
  AlarmClockAlt = 'alarm-clock-alt',
  AlarmClock = 'alarm-clock',
  AlarmExclamation = 'alarm-exclamation',
  AlarmPlus = 'alarm-plus',
  AlarmSnooze = 'alarm-snooze',
  AlbumCollection = 'album-collection',
  Album = 'album',
  AlignAlt = 'align-alt',
  AlignCenterAlt = 'align-center-alt',
  AlignCenterJustify = 'align-center-justify',
  AlignCenter = 'align-center',
  AlignJustify = 'align-justify',
  AlignLeftJustify = 'align-left-justify',
  AlignLeft = 'align-left',
  AlignLetterRight = 'align-letter-right',
  AlignRightJustify = 'align-right-justify',
  AlignRight = 'align-right',
  Anchor = 'anchor',
  Angel = 'angel',
  AnnotationUser = 'annotation-user',
  Annotation = 'annotation',
  Aperture = 'aperture',
  Archway = 'archway',
  ArrowCircleDownLeft = 'arrow-circle-down-left',
  ArrowCircleDownRight = 'arrow-circle-down-right',
  ArrowCircleDown = 'arrow-circle-down',
  ArrowCircleLeft = 'arrow-circle-left',
  ArrowCircleRight = 'arrow-circle-right',
  ArrowCircleUpLeft = 'arrow-circle-up-left',
  ArrowCircleUpRight = 'arrow-circle-up-right',
  ArrowCircleUp = 'arrow-circle-up',
  ArrowDown_1_9 = 'arrow-down-1-9',
  ArrowDown_9_1 = 'arrow-down-9-1',
  ArrowDownAZ = 'arrow-down-a-z',
  ArrowDownArrowUp = 'arrow-down-arrow-up',
  ArrowDownBigSmall = 'arrow-down-big-small',
  ArrowDownLeft = 'arrow-down-left',
  ArrowDownRight = 'arrow-down-right',
  ArrowDownShortWide = 'arrow-down-short-wide',
  ArrowDownSmallBig = 'arrow-down-small-big',
  ArrowDownToArc = 'arrow-down-to-arc',
  ArrowDownToBracket = 'arrow-down-to-bracket',
  ArrowDownToSquare = 'arrow-down-to-square',
  ArrowDownWideShort = 'arrow-down-wide-short',
  ArrowDownZA = 'arrow-down-z-a',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowNarrowBottomAlignment = 'arrow-narrow-bottom-alignment',
  ArrowNarrowCircleBrokenDownLeft = 'arrow-narrow-circle-broken-down-left',
  ArrowNarrowCircleBrokenDownRight = 'arrow-narrow-circle-broken-down-right',
  ArrowNarrowCircleBrokenDown = 'arrow-narrow-circle-broken-down',
  ArrowNarrowCircleBrokenLeft = 'arrow-narrow-circle-broken-left',
  ArrowNarrowCircleBrokenRight = 'arrow-narrow-circle-broken-right',
  ArrowNarrowCircleBrokenUpLeft = 'arrow-narrow-circle-broken-up-left',
  ArrowNarrowCircleBrokenUpRight = 'arrow-narrow-circle-broken-up-right',
  ArrowNarrowDownMove = 'arrow-narrow-down-move',
  ArrowNarrowDown = 'arrow-narrow-down',
  ArrowNarrowLeftAlignment = 'arrow-narrow-left-alignment',
  ArrowNarrowLeftMove = 'arrow-narrow-left-move',
  ArrowNarrowLeft = 'arrow-narrow-left',
  ArrowNarrowRightAlignment = 'arrow-narrow-right-alignment',
  ArrowNarrowRightMove = 'arrow-narrow-right-move',
  ArrowNarrowRight = 'arrow-narrow-right',
  ArrowNarrowTopAlignment = 'arrow-narrow-top-alignment',
  ArrowNarrowUpMove = 'arrow-narrow-up-move',
  ArrowNarrowUp = 'arrow-narrow-up',
  ArrowRightFromArc = 'arrow-right-from-arc',
  ArrowRightFromBracket = 'arrow-right-from-bracket',
  ArrowRightToArc = 'arrow-right-to-arc',
  ArrowRightToBracket = 'arrow-right-to-bracket',
  ArrowRight = 'arrow-right',
  ArrowRotateRight_24 = 'arrow-rotate-right-24',
  ArrowSmDown = 'arrow-sm-down',
  ArrowSmLeft = 'arrow-sm-left',
  ArrowSmRight = 'arrow-sm-right',
  ArrowSmUp = 'arrow-sm-up',
  ArrowSpin = 'arrow-spin',
  ArrowSquareDownLeft = 'arrow-square-down-left',
  ArrowSquareDownRight = 'arrow-square-down-right',
  ArrowSquareDown = 'arrow-square-down',
  ArrowSquareLeft = 'arrow-square-left',
  ArrowSquareRight = 'arrow-square-right',
  ArrowSquareUpLeft = 'arrow-square-up-left',
  ArrowSquareUpRight = 'arrow-square-up-right',
  ArrowSquareUp = 'arrow-square-up',
  ArrowUp_1_9 = 'arrow-up-1-9',
  ArrowUp_9_1 = 'arrow-up-9-1',
  ArrowUpAZ = 'arrow-up-a-z',
  ArrowUpBigSmall = 'arrow-up-big-small',
  ArrowUpFromArc = 'arrow-up-from-arc',
  ArrowUpFromBracket = 'arrow-up-from-bracket',
  ArrowUpFromSquare = 'arrow-up-from-square',
  ArrowUpLeft = 'arrow-up-left',
  ArrowUpRightFromSquare = 'arrow-up-right-from-square',
  ArrowUpRight = 'arrow-up-right',
  ArrowUpShortWide = 'arrow-up-short-wide',
  ArrowUpSmallBig = 'arrow-up-small-big',
  ArrowUpWideShort = 'arrow-up-wide-short',
  ArrowUpZA = 'arrow-up-z-a',
  ArrowUp = 'arrow-up',
  ArrowsLeftRight = 'arrows-left-right',
  ArrowsUpDown = 'arrows-up-down',
  Asterisk = 'asterisk',
  AtSign = 'at-sign',
  Atom = 'atom',
  AudioDescriptionSlasg = 'audio-description-slasg',
  AudioDescription = 'audio-description',
  AustralSign = 'austral-sign',
  AwardAlt = 'award-alt',
  Award = 'award',
  BackpackAlt = 'backpack-alt',
  Backpack = 'backpack',
  BackwardFast = 'backward-fast',
  BackwardStep = 'backward-step',
  Backward = 'backward',
  BadgeCheck = 'badge-check',
  BadgeDollar = 'badge-dollar',
  BadgePercent = 'badge-percent',
  Badge = 'badge',
  BagShopping = 'bag-shopping',
  BagsShopping = 'bags-shopping',
  BallPile = 'ball-pile',
  Balloon = 'balloon',
  Ban = 'ban',
  Bandage = 'bandage',
  Bank = 'bank',
  BarsProgress = 'bars-progress',
  BasketShoppingAlt = 'basket-shopping-alt',
  BasketShopping = 'basket-shopping',
  Bat = 'bat',
  BatteryBoltAlt = 'battery-bolt-alt',
  BatteryBolt = 'battery-bolt',
  BatteryEmpty = 'battery-empty',
  BatteryExclamation = 'battery-exclamation',
  BatteryFull = 'battery-full',
  BatteryLow = 'battery-low',
  BatteryMid = 'battery-mid',
  BatterySlash = 'battery-slash',
  Bed = 'bed',
  BellAlt_1 = 'bell-alt-1',
  BellAlt = 'bell-alt',
  BellSlash = 'bell-slash',
  Bell = 'bell',
  BitcoinCircle = 'bitcoin-circle',
  BitcoinSign = 'bitcoin-sign',
  BluetoothOn = 'bluetooth-on',
  BluetoothSignal = 'bluetooth-signal',
  BluetoothSlash = 'bluetooth-slash',
  Bold = 'bold',
  Bolt = 'bolt',
  Bones = 'bones',
  BookOpen = 'book-open',
  BookSection = 'book-section',
  BookSparkles = 'book-sparkles',
  BookUser = 'book-user',
  Book = 'book',
  Bookmark = 'bookmark',
  Boombox = 'boombox',
  BorderBottom = 'border-bottom',
  BorderClear = 'border-clear',
  BorderHorizontal = 'border-horizontal',
  BorderInner = 'border-inner',
  BorderLeft = 'border-left',
  BorderOuter = 'border-outer',
  BorderRight = 'border-right',
  BorderTopLeft = 'border-top-left',
  BorderTop = 'border-top',
  BorderVertical = 'border-vertical',
  BoxArchive = 'box-archive',
  BracketCurlyRight = 'bracket-curly-right',
  BracketCurly = 'bracket-curly',
  BracketRoundRight = 'bracket-round-right',
  BracketRound = 'bracket-round',
  BracketSquareRight = 'bracket-square-right',
  BracketSquare = 'bracket-square',
  BracketsCurly = 'brackets-curly',
  BracketsRound = 'brackets-round',
  BracketsSquare = 'brackets-square',
  BrakeWarning = 'brake-warning',
  BridgeSuspension = 'bridge-suspension',
  BriefcaseAlt_1 = 'briefcase-alt-1',
  BriefcaseAlt_2 = 'briefcase-alt-2',
  BriefcaseAlt_3 = 'briefcase-alt-3',
  BriefcaseAlt_4 = 'briefcase-alt-4',
  BriefcaseAlt = 'briefcase-alt',
  BriefcaseDollar = 'briefcase-dollar',
  BriefcaseMedical = 'briefcase-medical',
  BriefcasePlus = 'briefcase-plus',
  Briefcase = 'briefcase',
  Broom = 'broom',
  Browser = 'browser',
  Brush = 'brush',
  BugSlash = 'bug-slash',
  Bug = 'bug',
  BuildingArrowRight = 'building-arrow-right',
  BuildingCar = 'building-car',
  BuildingCheck = 'building-check',
  BuildingColumns = 'building-columns',
  BuildingExclamation = 'building-exclamation',
  BuildingFlag = 'building-flag',
  BuildingLock = 'building-lock',
  BuildingNgo = 'building-ngo',
  BuildingShield = 'building-shield',
  BuildingUn = 'building-un',
  BuildingUser = 'building-user',
  BuildingXmark = 'building-xmark',
  Building = 'building',
  Buildings = 'buildings',
  BullseyeArrow = 'bullseye-arrow',
  Bus = 'bus',
  CakeChristmas = 'cake-christmas',
  Calculator = 'calculator',
  CalendarAlt = 'calendar-alt',
  CalendarArrowDown = 'calendar-arrow-down',
  CalendarArrowUp = 'calendar-arrow-up',
  CalendarCheck = 'calendar-check',
  CalendarCircleExclamation = 'calendar-circle-exclamation',
  CalendarCircleMinus = 'calendar-circle-minus',
  CalendarCirclePlus = 'calendar-circle-plus',
  CalendarDay = 'calendar-day',
  CalendarDays = 'calendar-days',
  CalendarExclamationAlt = 'calendar-exclamation-alt',
  CalendarExclamation = 'calendar-exclamation',
  CalendarHeart = 'calendar-heart',
  CalendarLinesAlt = 'calendar-lines-alt',
  CalendarLinesPen = 'calendar-lines-pen',
  CalendarLines = 'calendar-lines',
  CalendarMinus = 'calendar-minus',
  CalendarPen = 'calendar-pen',
  CalendarPlusAlt = 'calendar-plus-alt',
  CalendarPlus = 'calendar-plus',
  CalendarUser = 'calendar-user',
  CalendarWeek = 'calendar-week',
  CalendarXmarkAlt = 'calendar-xmark-alt',
  CalendarXmark = 'calendar-xmark',
  Calendar = 'calendar',
  Calendars = 'calendars',
  Camcorder = 'camcorder',
  CameraAlt_1 = 'camera-alt-1',
  CameraAlt_2 = 'camera-alt-2',
  CameraAlt = 'camera-alt',
  CameraSlash = 'camera-slash',
  Camera = 'camera',
  Campground = 'campground',
  CandleHolder = 'candle-holder',
  CandleStand = 'candle-stand',
  CandyAlt = 'candy-alt',
  CandyCane = 'candy-cane',
  CandyCorn = 'candy-corn',
  Candy = 'candy',
  Capsule = 'capsule',
  CarBattery = 'car-battery',
  CarSide = 'car-side',
  Car = 'car',
  CaretDown = 'caret-down',
  CaretLeftToLine = 'caret-left-to-line',
  CaretLeft = 'caret-left',
  CaretRightToLine = 'caret-right-to-line',
  CaretRight = 'caret-right',
  CaretUp = 'caret-up',
  CartArrowDown = 'cart-arrow-down',
  CartArrowUp = 'cart-arrow-up',
  CartCheck = 'cart-check',
  CartMinus = 'cart-minus',
  CartPlus = 'cart-plus',
  CartShoppingFast = 'cart-shopping-fast',
  CartShopping = 'cart-shopping',
  CartXmark = 'cart-xmark',
  CashRegister = 'cash-register',
  CassetteTape = 'cassette-tape',
  Castle = 'castle',
  Cat = 'cat',
  Cauldron = 'cauldron',
  Cctv = 'cctv',
  CediSign = 'cedi-sign',
  CentSign = 'cent-sign',
  CertificateCheck = 'certificate-check',
  Certificate = 'certificate',
  ChartArea = 'chart-area',
  ChartBar = 'chart-bar',
  ChartBullet = 'chart-bullet',
  ChartColumn = 'chart-column',
  ChartGantt = 'chart-gantt',
  ChartLineDown = 'chart-line-down',
  ChartLineUp = 'chart-line-up',
  ChartLine = 'chart-line',
  ChartMixedAlt = 'chart-mixed-alt',
  ChartMixed = 'chart-mixed',
  ChartNetwork = 'chart-network',
  ChartPieAlt_1 = 'chart-pie-alt-1',
  ChartPieAlt = 'chart-pie-alt',
  ChartPieSimple = 'chart-pie-simple',
  ChartPie = 'chart-pie',
  ChartPyramid = 'chart-pyramid',
  ChartRadar = 'chart-radar',
  ChartScatter_3d = 'chart-scatter-3d',
  ChartScatterBubble = 'chart-scatter-bubble',
  ChartScatter = 'chart-scatter',
  ChartSimpleHorizontal = 'chart-simple-horizontal',
  ChartSimple = 'chart-simple',
  ChartTreeMap = 'chart-tree-map',
  ChartUserSquare = 'chart-user-square',
  ChartWaterfall = 'chart-waterfall',
  CheckDouble = 'check-double',
  Check = 'check',
  ChevronDownDot = 'chevron-down-dot',
  ChevronDownDouble = 'chevron-down-double',
  ChevronDownLeft = 'chevron-down-left',
  ChevronDownRight = 'chevron-down-right',
  ChevronDown = 'chevron-down',
  ChevronLeftDot = 'chevron-left-dot',
  ChevronLeftDouble = 'chevron-left-double',
  ChevronLeft = 'chevron-left',
  ChevronRightDot = 'chevron-right-dot',
  ChevronRightDouble = 'chevron-right-double',
  ChevronRight = 'chevron-right',
  ChevronSelectorHorizontalDot = 'chevron-selector-horizontal-dot',
  ChevronSelectorHorizontal = 'chevron-selector-horizontal',
  ChevronSelectorVerticalDot = 'chevron-selector-vertical-dot',
  ChevronSelectorVertical = 'chevron-selector-vertical',
  ChevronUpDot = 'chevron-up-dot',
  ChevronUpDouble = 'chevron-up-double',
  ChevronUpLeft = 'chevron-up-left',
  ChevronUpRight = 'chevron-up-right',
  ChevronUp = 'chevron-up',
  Chimney = 'chimney',
  ChirstmasStar = 'chirstmas-star',
  ChristmasBag = 'christmas-bag',
  ChristmasCandle = 'christmas-candle',
  ChristmasStar = 'christmas-star',
  Church = 'church',
  CircleBolt = 'circle-bolt',
  CircleCheck = 'circle-check',
  CircleDashed = 'circle-dashed',
  CircleDollarToSlot = 'circle-dollar-to-slot',
  CircleDollar = 'circle-dollar',
  CircleDotsHorizontal = 'circle-dots-horizontal',
  CircleDotsVertical = 'circle-dots-vertical',
  CircleExclamationCheck = 'circle-exclamation-check',
  CircleExclamation = 'circle-exclamation',
  CircleHalfStroke = 'circle-half-stroke',
  CircleHalf = 'circle-half',
  CircleHeart = 'circle-heart',
  CircleHeat = 'circle-heat',
  CircleInformation = 'circle-information',
  CircleLocationArrow = 'circle-location-arrow',
  CircleMicrophone = 'circle-microphone',
  CircleMinus = 'circle-minus',
  CircleNotch = 'circle-notch',
  CircleParkingSplash = 'circle-parking-splash',
  CircleParking = 'circle-parking',
  CirclePause = 'circle-pause',
  CirclePercentage = 'circle-percentage',
  CirclePlay = 'circle-play',
  CirclePlugAlt = 'circle-plug-alt',
  CirclePlug = 'circle-plug',
  CirclePlus = 'circle-plus',
  CircleQuarter = 'circle-quarter',
  CircleQuarters = 'circle-quarters',
  CircleQuestion = 'circle-question',
  CircleRadiation = 'circle-radiation',
  CircleStop = 'circle-stop',
  CircleThreeQuarters = 'circle-three-quarters',
  CircleUser = 'circle-user',
  CircleVideo = 'circle-video',
  CircleWaterPlug = 'circle-water-plug',
  CircleWaveformLines = 'circle-waveform-lines',
  CircleXmark = 'circle-xmark',
  CircleYen = 'circle-yen',
  Circle = 'circle',
  Circuit = 'circuit',
  City = 'city',
  ClapperboardPlay = 'clapperboard-play',
  Clapperboard = 'clapperboard',
  Clarinet = 'clarinet',
  CleaverBlood = 'cleaver-blood',
  Cleaver = 'cleaver',
  ClipboardArrowDown = 'clipboard-arrow-down',
  ClipboardArrowUp = 'clipboard-arrow-up',
  ClipboardAttachment = 'clipboard-attachment',
  ClipboardListAlt = 'clipboard-list-alt',
  ClipboardList = 'clipboard-list',
  ClipboardMinus = 'clipboard-minus',
  ClipboardPlus = 'clipboard-plus',
  ClipboardTextAlt = 'clipboard-text-alt',
  ClipboardText = 'clipboard-text',
  ClipboardUser = 'clipboard-user',
  ClipboardXmark = 'clipboard-xmark',
  Clipboard = 'clipboard',
  Cloche = 'cloche',
  ClockCheck = 'clock-check',
  ClockDesk = 'clock-desk',
  ClockEightThirty = 'clock-eight-thirty',
  ClockEight = 'clock-eight',
  ClockElevenThirty = 'clock-eleven-thirty',
  ClockEleven = 'clock-eleven',
  ClockExclamation = 'clock-exclamation',
  ClockFiveThirty = 'clock-five-thirty',
  ClockFive = 'clock-five',
  ClockFourThirty = 'clock-four-thirty',
  ClockLines = 'clock-lines',
  ClockMinus = 'clock-minus',
  ClockNineThirty = 'clock-nine-thirty',
  ClockNine = 'clock-nine',
  ClockOneThirty = 'clock-one-thirty',
  ClockOne = 'clock-one',
  ClockPlus = 'clock-plus',
  ClockSevenThirty = 'clock-seven-thirty',
  ClockSeven = 'clock-seven',
  ClockSixThirty = 'clock-six-thirty',
  ClockSix = 'clock-six',
  ClockSlash = 'clock-slash',
  ClockSnooze = 'clock-snooze',
  ClockTenThirty = 'clock-ten-thirty',
  ClockTen = 'clock-ten',
  ClockThreeThirty = 'clock-three-thirty',
  ClockThree = 'clock-three',
  ClockTwelveThirty = 'clock-twelve-thirty',
  ClockTwelve = 'clock-twelve',
  ClockTwoThirty = 'clock-two-thirty',
  ClockTwo = 'clock-two',
  ClockXmark = 'clock-xmark',
  Clock = 'clock',
  Clone = 'clone',
  ClosedCaptioning = 'closed-captioning',
  CloudArrowDownAlt = 'cloud-arrow-down-alt',
  CloudArrowDown = 'cloud-arrow-down',
  CloudArrowUp = 'cloud-arrow-up',
  CloudBolt = 'cloud-bolt',
  CloudCheck = 'cloud-check',
  CloudExclamation = 'cloud-exclamation',
  CloudFog = 'cloud-fog',
  CloudLock = 'cloud-lock',
  CloudMinus = 'cloud-minus',
  CloudMoon = 'cloud-moon',
  CloudMusic = 'cloud-music',
  CloudPlus = 'cloud-plus',
  CloudQuestion = 'cloud-question',
  CloudRainAlt_1 = 'cloud-rain-alt-1',
  CloudRainAlt = 'cloud-rain-alt',
  CloudRain = 'cloud-rain',
  CloudRainbow = 'cloud-rainbow',
  CloudSearch = 'cloud-search',
  CloudShowers = 'cloud-showers',
  CloudSlash = 'cloud-slash',
  CloudSleet = 'cloud-sleet',
  CloudSnowAlt = 'cloud-snow-alt',
  CloudSnow = 'cloud-snow',
  CloudSunAlt = 'cloud-sun-alt',
  CloudSun = 'cloud-sun',
  CloudUpArrow = 'cloud-up-arrow',
  CloudWired = 'cloud-wired',
  CloudXmark = 'cloud-xmark',
  Cloud = 'cloud',
  Clouds = 'clouds',
  Club = 'club',
  CodeAlt = 'code-alt',
  CodeBranch = 'code-branch',
  CodeCommit = 'code-commit',
  CodeCompare = 'code-compare',
  CodeFork = 'code-fork',
  CodeMerge = 'code-merge',
  CodePullRequestClosed = 'code-pull-request-closed',
  CodePullRequest = 'code-pull-request',
  Code = 'code',
  CoffinCross = 'coffin-cross',
  CoffinOpen = 'coffin-open',
  Coffin = 'coffin',
  CoinAlt = 'coin-alt',
  CoinFront = 'coin-front',
  CoinVertical = 'coin-vertical',
  Coin = 'coin',
  CoinsAlt = 'coins-alt',
  Coins = 'coins',
  ColonSign = 'colon-sign',
  Colors = 'colors',
  Columns_2 = 'columns-2',
  Columns_3 = 'columns-3',
  Command = 'command',
  CompactDisc = 'compact-disc',
  CompassDrafting = 'compass-drafting',
  Compass = 'compass',
  CompressAlt_1 = 'compress-alt-1',
  CompressAlt_2 = 'compress-alt-2',
  CompressAlt = 'compress-alt',
  CompressWide = 'compress-wide',
  Compress = 'compress',
  ContainerStorage = 'container-storage',
  CookieMan = 'cookie-man',
  Cookie = 'cookie',
  Cookies = 'cookies',
  CornerDownLeft = 'corner-down-left',
  CornerDownRight = 'corner-down-right',
  CornerLeftDown = 'corner-left-down',
  CornerLeftUp = 'corner-left-up',
  CornerRightDown = 'corner-right-down',
  CornerRightUp = 'corner-right-up',
  CornerUpLeft = 'corner-up-left',
  CornerUpRight = 'corner-up-right',
  CreditCardAlt_1 = 'credit-card-alt-1',
  CreditCardAlt = 'credit-card-alt',
  CreditCardArrowRightAlt = 'credit-card-arrow-right-alt',
  CreditCardArrowRight = 'credit-card-arrow-right',
  CreditCardMinus = 'credit-card-minus',
  CreditCardPlus = 'credit-card-plus',
  CreditCardScan = 'credit-card-scan',
  CreditCard = 'credit-card',
  CropSimple = 'crop-simple',
  Crop = 'crop',
  CrosshairSimple = 'crosshair-simple',
  Crosshair = 'crosshair',
  Crow = 'crow',
  Crown = 'crown',
  CruzeiroSign = 'cruzeiro-sign',
  CubeAlt_1 = 'cube-alt-1',
  CubeAlt_2 = 'cube-alt-2',
  CubeAlt = 'cube-alt',
  Cube = 'cube',
  CursorAlt = 'cursor-alt',
  Cursor = 'cursor',
  Dash = 'dash',
  Dazzle = 'dazzle',
  DazzlingStar = 'dazzling-star',
  DeleteLeft = 'delete-left',
  DeleteRight = 'delete-right',
  DesktopComputer = 'desktop-computer',
  DiagramCells = 'diagram-cells',
  DiagramLeanCanvas = 'diagram-lean-canvas',
  DiagramNested = 'diagram-nested',
  DiagramNext = 'diagram-next',
  DiagramPredecessor = 'diagram-predecessor',
  DiagramPrevious = 'diagram-previous',
  DiagramProject_1 = 'diagram-project-1',
  DiagramProject = 'diagram-project',
  DiagramSankey = 'diagram-sankey',
  DiagramSubtask = 'diagram-subtask',
  DiagramSuccessor = 'diagram-successor',
  DiagramVenn = 'diagram-venn',
  DialHigh = 'dial-high',
  DialLow = 'dial-low',
  DialMax = 'dial-max',
  DialMedLow = 'dial-med-low',
  DialMed = 'dial-med',
  DialMin = 'dial-min',
  DialOff = 'dial-off',
  Dial = 'dial',
  DialpadCircle = 'dialpad-circle',
  DialpadSquare = 'dialpad-square',
  DiamondExclamation = 'diamond-exclamation',
  DiamondTurnRight = 'diamond-turn-right',
  DiamondXmark = 'diamond-xmark',
  Diamond = 'diamond',
  DiceFive = 'dice-five',
  DiceFour = 'dice-four',
  DiceOne = 'dice-one',
  DiceSix = 'dice-six',
  DiceThree = 'dice-three',
  DiceTwo = 'dice-two',
  DiscAlt = 'disc-alt',
  DiscDrive = 'disc-drive',
  Disc = 'disc',
  DistributeSpacingHorizontalAlt = 'distribute-spacing-horizontal-alt',
  DistributeSpacingHorizontal = 'distribute-spacing-horizontal',
  DistributeSpacingVerticalAlt = 'distribute-spacing-vertical-alt',
  DistributeSpacingVertical = 'distribute-spacing-vertical',
  Dna = 'dna',
  DoNotEnter = 'do-not-enter',
  DocumentLayoutCenter = 'document-layout-center',
  DocumentLayoutLeft = 'document-layout-left',
  DocumentLayoutRight = 'document-layout-right',
  DollarCircleList = 'dollar-circle-list',
  DollarSign = 'dollar-sign',
  DongSign = 'dong-sign',
  DoorClosed = 'door-closed',
  DoorOpen = 'door-open',
  DotsHorizontalAlt = 'dots-horizontal-alt',
  DotsHorizontal = 'dots-horizontal',
  DotsVerticalAlt = 'dots-vertical-alt',
  DotsVertical = 'dots-vertical',
  Download = 'download',
  DrawSquareT = 'draw-square-t',
  DrawSquare = 'draw-square',
  DrawingTablet = 'drawing-tablet',
  Drone = 'drone',
  DropletAlt = 'droplet-alt',
  DropletDegree = 'droplet-degree',
  DropletSlash = 'droplet-slash',
  Droplet = 'droplet',
  Droplets = 'droplets',
  Drum = 'drum',
  Eclipse = 'eclipse',
  EngineWarning = 'engine-warning',
  Engine = 'engine',
  EnglishToChinese = 'english-to-chinese',
  Enter = 'enter',
  EuroCircle = 'euro-circle',
  EuroSign = 'euro-sign',
  ExclamationAlt = 'exclamation-alt',
  Exclamation = 'exclamation',
  ExpandAlt_1 = 'expand-alt-1',
  ExpandAlt_2 = 'expand-alt-2',
  ExpandAlt = 'expand-alt',
  ExpandWide = 'expand-wide',
  Expand = 'expand',
  EyeAlt = 'eye-alt',
  EyeDropperFull = 'eye-dropper-full',
  EyeDropperHalf = 'eye-dropper-half',
  EyeDropper = 'eye-dropper',
  EyeSlashAlt = 'eye-slash-alt',
  EyeSlash = 'eye-slash',
  Eye = 'eye',
  FaceFrown = 'face-frown',
  FaceId = 'face-id',
  FaceSmile = 'face-smile',
  Farm = 'farm',
  Fence = 'fence',
  FerrisWheel = 'ferris-wheel',
  Figma = 'figma',
  FileAlt = 'file-alt',
  FileArrowDownAlt_1 = 'file-arrow-down-alt-1',
  FileArrowDownAlt = 'file-arrow-down-alt',
  FileArrowDown = 'file-arrow-down',
  FileArrowLeft = 'file-arrow-left',
  FileArrowRight = 'file-arrow-right',
  FileArrowUpAlt_1 = 'file-arrow-up-alt-1',
  FileArrowUpAlt = 'file-arrow-up-alt',
  FileArrowUp = 'file-arrow-up',
  FileAudio = 'file-audio',
  FileBlockAlt_1 = 'file-block-alt-1',
  FileBlockAlt = 'file-block-alt',
  FileBlock = 'file-block',
  FileBookmarkAlt_1 = 'file-bookmark-alt-1',
  FileBookmarkAlt = 'file-bookmark-alt',
  FileBookmark = 'file-bookmark',
  FileChartColumn = 'file-chart-column',
  FileCheckAlt_1 = 'file-check-alt-1',
  FileCheckAlt = 'file-check-alt',
  FileCheck = 'file-check',
  FileCode = 'file-code',
  FileContract = 'file-contract',
  FileCopy = 'file-copy',
  FileCv = 'file-cv',
  FileDashedLine = 'file-dashed-line',
  FileDollarAlt = 'file-dollar-alt',
  FileDollar = 'file-dollar',
  FileExclamationAlt_1 = 'file-exclamation-alt-1',
  FileExclamationAlt = 'file-exclamation-alt',
  FileExclamation = 'file-exclamation',
  FileGraph = 'file-graph',
  FileHeartAlt_1 = 'file-heart-alt-1',
  FileHeartAlt = 'file-heart-alt',
  FileHeart = 'file-heart',
  FileHorizontal = 'file-horizontal',
  FileInfoAlt_1 = 'file-info-alt-1',
  FileInfoAlt = 'file-info-alt',
  FileInfo = 'file-info',
  FileLink = 'file-link',
  FileLockAlt_1 = 'file-lock-alt-1',
  FileLockAlt = 'file-lock-alt',
  FileLock = 'file-lock',
  FileMinusAlt_1 = 'file-minus-alt-1',
  FileMinusAlt = 'file-minus-alt',
  FileMinus = 'file-minus',
  FilePencilAlt_1 = 'file-pencil-alt-1',
  FilePencilAlt = 'file-pencil-alt',
  FilePencil = 'file-pencil',
  FilePlusAlt_1 = 'file-plus-alt-1',
  FilePlusAlt = 'file-plus-alt',
  FilePlus = 'file-plus',
  FileQuestionAlt_1 = 'file-question-alt-1',
  FileQuestionAlt = 'file-question-alt',
  FileQuestion = 'file-question',
  FileRedoAlt_1 = 'file-redo-alt-1',
  FileRedoAlt = 'file-redo-alt',
  FileRedo = 'file-redo',
  FileSearchAlt_1 = 'file-search-alt-1',
  FileSearchAlt = 'file-search-alt',
  FileSearch = 'file-search',
  FileShieldAlt_1 = 'file-shield-alt-1',
  FileShieldAlt = 'file-shield-alt',
  FileShield = 'file-shield',
  FileShredder = 'file-shredder',
  FileSlash = 'file-slash',
  FileUser = 'file-user',
  FileWired = 'file-wired',
  FileXmarkAlt_1 = 'file-xmark-alt-1',
  FileXmarkAlt = 'file-xmark-alt',
  FileXmark = 'file-xmark',
  FileZipper = 'file-zipper',
  File = 'file',
  FilesAlt_1 = 'files-alt-1',
  FilesAlt_2 = 'files-alt-2',
  FilesAlt = 'files-alt',
  Files = 'files',
  FilmAlt = 'film-alt',
  FilmSlash = 'film-slash',
  Film = 'film',
  FilterList = 'filter-list',
  FilterSlash = 'filter-slash',
  FilterXmark = 'filter-xmark',
  Filter = 'filter',
  FingerprintAlt = 'fingerprint-alt',
  Fingerprint = 'fingerprint',
  Fireplace = 'fireplace',
  Firewall = 'firewall',
  Flag = 'flag',
  Flashlight = 'flashlight',
  FlaskRoundBlank = 'flask-round-blank',
  FlaskRoundPotion = 'flask-round-potion',
  FlipBackward = 'flip-backward',
  FlipForwardEnergy = 'flip-forward-energy',
  FlipForward = 'flip-forward',
  FloppyDiskAlt = 'floppy-disk-alt',
  FloppyDisk = 'floppy-disk',
  Flute = 'flute',
  FolderArrowDown = 'folder-arrow-down',
  FolderArrowLeft = 'folder-arrow-left',
  FolderArrowRight = 'folder-arrow-right',
  FolderArrowUp = 'folder-arrow-up',
  FolderBlock = 'folder-block',
  FolderCheck = 'folder-check',
  FolderExclamation = 'folder-exclamation',
  FolderHeart = 'folder-heart',
  FolderImage = 'folder-image',
  FolderInfo = 'folder-info',
  FolderLock = 'folder-lock',
  FolderMinus = 'folder-minus',
  FolderPlus = 'folder-plus',
  FolderQuestion = 'folder-question',
  FolderSearch = 'folder-search',
  FolderShield = 'folder-shield',
  FolderUser = 'folder-user',
  FolderXmark = 'folder-xmark',
  Folder = 'folder',
  FontCase = 'font-case',
  Font = 'font',
  ForkKnife = 'fork-knife',
  ForkSpoon = 'fork-spoon',
  ForwardStep = 'forward-step',
  Forward = 'forward',
  Frame = 'frame',
  Frankenstein = 'frankenstein',
  GalleryThumbnails = 'gallery-thumbnails',
  GamingPadAlt_1 = 'gaming-pad-alt-1',
  GamingPadAlt = 'gaming-pad-alt',
  GamingPad = 'gaming-pad',
  GarageOpen = 'garage-open',
  Garage = 'garage',
  GaugeHigh = 'gauge-high',
  GaugeLow = 'gauge-low',
  GaugeMax = 'gauge-max',
  Gauge = 'gauge',
  GearAlt = 'gear-alt',
  Gear = 'gear',
  GemAlt = 'gem-alt',
  Gem = 'gem',
  GhostSmile = 'ghost-smile',
  Ghost = 'ghost',
  GiftAlt = 'gift-alt',
  GiftCard = 'gift-card',
  Gift = 'gift',
  Glasses = 'glasses',
  GlobeAlt_1 = 'globe-alt-1',
  GlobeAlt = 'globe-alt',
  GlobeDollar = 'globe-dollar',
  GlobeStand = 'globe-stand',
  Globe = 'globe',
  Gold = 'gold',
  GraduationHatAlt_1 = 'graduation-hat-alt-1',
  GraduationHatAlt_2 = 'graduation-hat-alt-2',
  GraduationHatAlt = 'graduation-hat-alt',
  GraduationHat = 'graduation-hat',
  GridCircle = 'grid-circle',
  GridDividers = 'grid-dividers',
  GridHorizontal = 'grid-horizontal',
  GridPlus = 'grid-plus',
  GridSearch = 'grid-search',
  GridVertical = 'grid-vertical',
  Grid = 'grid',
  GripDotsVertical = 'grip-dots-vertical',
  GripDots = 'grip-dots',
  GripLinesVertical = 'grip-lines-vertical',
  GripLines = 'grip-lines',
  H1 = 'h1',
  H2 = 'h2',
  HalloweenCalendarAlt = 'halloween-calendar-alt',
  HalloweenCalendarBat = 'halloween-calendar-bat',
  HalloweenCalendar = 'halloween-calendar',
  HalloweenDecorations = 'halloween-decorations',
  HalloweenMailAlt = 'halloween-mail-alt',
  HalloweenMail = 'halloween-mail',
  Handshake = 'handshake',
  HardDrive = 'hard-drive',
  HashtagLock = 'hashtag-lock',
  Hashtag = 'hashtag',
  HatWitch = 'hat-witch',
  HatWizardAlt = 'hat-wizard-alt',
  HatWizard = 'hat-wizard',
  Heading = 'heading',
  HeadphonesAlt_1 = 'headphones-alt-1',
  HeadphonesAlt_2 = 'headphones-alt-2',
  HeadphonesAlt = 'headphones-alt',
  Headphones = 'headphones',
  HeartAlt = 'heart-alt',
  HeartCrack = 'heart-crack',
  HeartHalfStroke = 'heart-half-stroke',
  HeartHalf = 'heart-half',
  Heart = 'heart',
  HeatAlt = 'heat-alt',
  Heat = 'heat',
  HexagonAlt_1 = 'hexagon-alt-1',
  HexagonCheck = 'hexagon-check',
  HexagonExclamation = 'hexagon-exclamation',
  HexagonImage = 'hexagon-image',
  Hexagon = 'hexagon',
  HighDefinition = 'high-definition',
  HockeyMask = 'hockey-mask',
  HorizontalRule = 'horizontal-rule',
  HospitalUser = 'hospital-user',
  Hospital = 'hospital',
  Hospitals = 'hospitals',
  Hotel = 'hotel',
  HourglassEnd = 'hourglass-end',
  HourglassHalf = 'hourglass-half',
  HourglassStart = 'hourglass-start',
  Hourglass = 'hourglass',
  HouseBoltAlt = 'house-bolt-alt',
  HouseBolt = 'house-bolt',
  HouseBuilding = 'house-building',
  HouseCelcius = 'house-celcius',
  HouseChimneyBlank = 'house-chimney-blank',
  HouseChimneyFloor = 'house-chimney-floor',
  HouseChimneyUser = 'house-chimney-user',
  HouseCrack = 'house-crack',
  HouseFire = 'house-fire',
  HouseFlag = 'house-flag',
  HouseFloor = 'house-floor',
  HouseLine = 'house-line',
  HouseLockAlt = 'house-lock-alt',
  HouseLock = 'house-lock',
  HouseMedicalCheck = 'house-medical-check',
  HouseMedicalExclamation = 'house-medical-exclamation',
  HouseMedicalFlag = 'house-medical-flag',
  HouseMedicalXmark = 'house-medical-xmark',
  HouseMedical = 'house-medical',
  HouseShield = 'house-shield',
  HouseSmile = 'house-smile',
  HouseSun = 'house-sun',
  HouseTree = 'house-tree',
  HouseTurret = 'house-turret',
  HouseUser = 'house-user',
  HouseWater = 'house-water',
  HouseWifi = 'house-wifi',
  HouseWindow = 'house-window',
  HurricaneAlt_1 = 'hurricane-alt-1',
  HurricaneAlt = 'hurricane-alt',
  Hurricane = 'hurricane',
  ICursor = 'i-cursor',
  Icicles = 'icicles',
  IdBadge = 'id-badge',
  IdCardClip = 'id-card-clip',
  IdCard = 'id-card',
  ImageCircle = 'image-circle',
  ImageMinus = 'image-minus',
  ImagePen = 'image-pen',
  ImagePlus = 'image-plus',
  ImagePolaroidUser = 'image-polaroid-user',
  ImagePortrait = 'image-portrait',
  ImageSquareCheck = 'image-square-check',
  ImageSquareXmark = 'image-square-xmark',
  ImageSquare = 'image-square',
  ImageUser = 'image-user',
  Image = 'image',
  ImagesUser = 'images-user',
  InboxAlt_1 = 'inbox-alt-1',
  InboxAlt_2 = 'inbox-alt-2',
  InboxAlt = 'inbox-alt',
  InboxArrowDown = 'inbox-arrow-down',
  InboxArrowLeft = 'inbox-arrow-left',
  InboxArrowRight = 'inbox-arrow-right',
  InboxArrowUp = 'inbox-arrow-up',
  InboxCheck = 'inbox-check',
  InboxExclamation = 'inbox-exclamation',
  InboxHeart = 'inbox-heart',
  InboxInformation = 'inbox-information',
  InboxMinus = 'inbox-minus',
  InboxPlus = 'inbox-plus',
  InboxStar = 'inbox-star',
  InboxXmark = 'inbox-xmark',
  Inbox = 'inbox',
  Indent = 'indent',
  IndustryWindows = 'industry-windows',
  Industry = 'industry',
  Infinity = 'infinity',
  InformationAlt = 'information-alt',
  Information = 'information',
  Italic = 'italic',
  JackOLantern = 'jack-o-lantern',
  Kerning = 'kerning',
  KeyAlt = 'key-alt',
  Key = 'key',
  KeyboardAlt_1 = 'keyboard-alt-1',
  KeyboardAlt = 'keyboard-alt',
  Keyboard = 'keyboard',
  KitMedical = 'kit-medical',
  KnifeKitchen = 'knife-kitchen',
  LampDesk = 'lamp-desk',
  LandmarkDome = 'landmark-dome',
  LandmarkFlag = 'landmark-flag',
  Landmark = 'landmark',
  LaptopAlt_1 = 'laptop-alt-1',
  LaptopAlt_2 = 'laptop-alt-2',
  LaptopAlt = 'laptop-alt',
  LaptopArrowDownAlt = 'laptop-arrow-down-alt',
  LaptopArrowDown = 'laptop-arrow-down',
  LaptopArrowUpAlt = 'laptop-arrow-up-alt',
  LaptopArrowUp = 'laptop-arrow-up',
  LaptopCheckAlt = 'laptop-check-alt',
  LaptopCheck = 'laptop-check',
  LaptopDollar = 'laptop-dollar',
  LaptopExclamationAlt = 'laptop-exclamation-alt',
  LaptopExclamation = 'laptop-exclamation',
  LaptopLockAlt = 'laptop-lock-alt',
  LaptopLock = 'laptop-lock',
  LaptopMedical = 'laptop-medical',
  LaptopRefreshAlt = 'laptop-refresh-alt',
  LaptopRefresh = 'laptop-refresh',
  LaptopSearchAlt = 'laptop-search-alt',
  LaptopSearch = 'laptop-search',
  LaptopShieldAlt = 'laptop-shield-alt',
  LaptopShield = 'laptop-shield',
  LaptopSignalAlt = 'laptop-signal-alt',
  LaptopSignal = 'laptop-signal',
  LaptopWired = 'laptop-wired',
  LaptopXmarkAlt = 'laptop-xmark-alt',
  LaptopXmark = 'laptop-xmark',
  Laptop = 'laptop',
  LassoSparkles = 'lasso-sparkles',
  Lasso = 'lasso',
  LayerGroup = 'layer-group',
  LeftIndent = 'left-indent',
  LeftToRightTextDirection = 'left-to-right-text-direction',
  LetterChineseA = 'letter-chinese-a',
  LetterEnglishA = 'letter-english-a',
  LetterJapaneseA = 'letter-japanese-a',
  LifeRing = 'life-ring',
  LightEmergencyOn = 'light-emergency-on',
  LightEmergency = 'light-emergency',
  LightbulbAlt = 'lightbulb-alt',
  LightbulbExclamationAlt = 'lightbulb-exclamation-alt',
  LightbulbExclamation = 'lightbulb-exclamation',
  Lightbulb = 'lightbulb',
  LightsHoliday = 'lights-holiday',
  LineColumns = 'line-columns',
  LineHeight = 'line-height',
  LinesLeaning = 'lines-leaning',
  LinkAlt_1 = 'link-alt-1',
  LinkAlt = 'link-alt',
  LinkSlashAlt = 'link-slash-alt',
  LinkSlash = 'link-slash',
  Link = 'link',
  LiraSign = 'lira-sign',
  ListCheck = 'list-check',
  ListMusic = 'list-music',
  ListOl = 'list-ol',
  ListUlAlt_1 = 'list-ul-alt-1',
  ListUlAlt = 'list-ul-alt',
  ListUl = 'list-ul',
  List = 'list',
  Loader = 'loader',
  LocationArrowAlt = 'location-arrow-alt',
  LocationArrow = 'location-arrow',
  LocationCheck = 'location-check',
  LocationCrosshairsSlash = 'location-crosshairs-slash',
  LocationCrosshairs = 'location-crosshairs',
  LocationExclamation = 'location-exclamation',
  LocationMinus = 'location-minus',
  LocationPinAlt_1 = 'location-pin-alt-1',
  LocationPinAlt = 'location-pin-alt',
  LocationPinLock = 'location-pin-lock',
  LocationPinSlash = 'location-pin-slash',
  LocationPin = 'location-pin',
  LocationPlus = 'location-plus',
  LocationXmark = 'location-xmark',
  LockA = 'lock-a',
  LockAlt = 'lock-alt',
  LockHashtag = 'lock-hashtag',
  LockSlash = 'lock-slash',
  Lock = 'lock',
  Lollipop = 'lollipop',
  LoopVertical = 'loop-vertical',
  Loop = 'loop',
  Magnet = 'magnet',
  MailAlt_1 = 'mail-alt-1',
  MailAlt_2 = 'mail-alt-2',
  MailAlt_3 = 'mail-alt-3',
  MailAlt = 'mail-alt',
  MailArrowDown = 'mail-arrow-down',
  MailArrowUp = 'mail-arrow-up',
  MailBlock = 'mail-block',
  MailBookmark = 'mail-bookmark',
  MailCheck = 'mail-check',
  MailExclamation = 'mail-exclamation',
  MailHeart = 'mail-heart',
  MailInfo = 'mail-info',
  MailLock = 'mail-lock',
  MailMinus = 'mail-minus',
  MailNotification = 'mail-notification',
  MailOpenAlt_1 = 'mail-open-alt-1',
  MailOpenAlt = 'mail-open-alt',
  MailOpenArrowDown = 'mail-open-arrow-down',
  MailOpenArrowUp = 'mail-open-arrow-up',
  MailOpenCheck = 'mail-open-check',
  MailOpenExclamation = 'mail-open-exclamation',
  MailOpenInfo = 'mail-open-info',
  MailOpenMinus = 'mail-open-minus',
  MailOpenPlus = 'mail-open-plus',
  MailOpenXmark = 'mail-open-xmark',
  MailOpen = 'mail-open',
  MailPencil = 'mail-pencil',
  MailPlus = 'mail-plus',
  MailRefresh = 'mail-refresh',
  MailSearch = 'mail-search',
  MailShield = 'mail-shield',
  MailStar = 'mail-star',
  MailXmark = 'mail-xmark',
  Mail = 'mail',
  MailboxAlt = 'mailbox-alt',
  Mailbox = 'mailbox',
  MapLocationPin = 'map-location-pin',
  MapPinAlt = 'map-pin-alt',
  MapPin = 'map-pin',
  Map = 'map',
  Marker = 'marker',
  MartiniGlass = 'martini-glass',
  MaskFace = 'mask-face',
  Mask = 'mask',
  Maximize = 'maximize',
  MedalAlt = 'medal-alt',
  Medal = 'medal',
  MemoCheck = 'memo-check',
  MemoPencil = 'memo-pencil',
  Memo = 'memo',
  MenorahAlt_1 = 'menorah-alt-1',
  MenorahAlt = 'menorah-alt',
  Menorah = 'menorah',
  MenuAlt_1 = 'menu-alt-1',
  MenuAlt_2 = 'menu-alt-2',
  MenuAlt = 'menu-alt',
  Menu = 'menu',
  MessageCircleArrowDown = 'message-circle-arrow-down',
  MessageCircleArrowUp = 'message-circle-arrow-up',
  MessageCircleBlock = 'message-circle-block',
  MessageCircleChartLines = 'message-circle-chart-lines',
  MessageCircleChat = 'message-circle-chat',
  MessageCircleCheck = 'message-circle-check',
  MessageCircleDots = 'message-circle-dots',
  MessageCircleExclamation = 'message-circle-exclamation',
  MessageCircleHeart = 'message-circle-heart',
  MessageCircleImage = 'message-circle-image',
  MessageCircleInfo = 'message-circle-info',
  MessageCircleLinesAlt = 'message-circle-lines-alt',
  MessageCircleLines = 'message-circle-lines',
  MessageCircleList = 'message-circle-list',
  MessageCircleLock = 'message-circle-lock',
  MessageCircleMedical = 'message-circle-medical',
  MessageCircleNotification = 'message-circle-notification',
  MessageCirclePencil = 'message-circle-pencil',
  MessageCirclePlus = 'message-circle-plus',
  MessageCircleQuestion = 'message-circle-question',
  MessageCircleRefresh = 'message-circle-refresh',
  MessageCircleSearch = 'message-circle-search',
  MessageCircleShield = 'message-circle-shield',
  MessageCircleXmark = 'message-circle-xmark',
  MessageCircle = 'message-circle',
  MessageSquareArrowDown = 'message-square-arrow-down',
  MessageSquareArrowUp = 'message-square-arrow-up',
  MessageSquareBlock = 'message-square-block',
  MessageSquareChartLines = 'message-square-chart-lines',
  MessageSquareChat = 'message-square-chat',
  MessageSquareCheck = 'message-square-check',
  MessageSquareDots = 'message-square-dots',
  MessageSquareExclamation = 'message-square-exclamation',
  MessageSquareHeart = 'message-square-heart',
  MessageSquareImage = 'message-square-image',
  MessageSquareInfo = 'message-square-info',
  MessageSquareLinesAlt = 'message-square-lines-alt',
  MessageSquareLines = 'message-square-lines',
  MessageSquareList = 'message-square-list',
  MessageSquareLock = 'message-square-lock',
  MessageSquareMedical = 'message-square-medical',
  MessageSquareNotification = 'message-square-notification',
  MessageSquarePencil = 'message-square-pencil',
  MessageSquarePlus = 'message-square-plus',
  MessageSquareQuestion = 'message-square-question',
  MessageSquareRefresh = 'message-square-refresh',
  MessageSquareSearch = 'message-square-search',
  MessageSquareShield = 'message-square-shield',
  MessageSquareXmark = 'message-square-xmark',
  MessageSquare = 'message-square',
  Microchip = 'microchip',
  MicrophoneAlt_1 = 'microphone-alt-1',
  MicrophoneAlt = 'microphone-alt',
  MicrophoneSlashAlt_1 = 'microphone-slash-alt-1',
  MicrophoneSlashAlt = 'microphone-slash-alt',
  MicrophoneSlash = 'microphone-slash',
  Microphone = 'microphone',
  Microscope = 'microscope',
  Minimize = 'minimize',
  Minus = 'minus',
  Mistletoe = 'mistletoe',
  MobileAlt_1 = 'mobile-alt-1',
  MobileAlt_2 = 'mobile-alt-2',
  MobileAlt = 'mobile-alt',
  MobileBolt = 'mobile-bolt',
  MobileCheck = 'mobile-check',
  MobileDollar = 'mobile-dollar',
  MobileSignalOut = 'mobile-signal-out',
  MobileSignal = 'mobile-signal',
  MobileSlash = 'mobile-slash',
  MobileXmark = 'mobile-xmark',
  Mobile = 'mobile',
  ModemAlt_1 = 'modem-alt-1',
  ModemAlt_2 = 'modem-alt-2',
  ModemAlt_3 = 'modem-alt-3',
  ModemAlt = 'modem-alt',
  Modem = 'modem',
  MoneyBillStack = 'money-bill-stack',
  MoneyBill = 'money-bill',
  MoneyCheckDollarPen = 'money-check-dollar-pen',
  MoneyCheckDollar = 'money-check-dollar',
  MoneyCheckPen = 'money-check-pen',
  MoneyCheck = 'money-check',
  MoneyInsert = 'money-insert',
  MoneyStack = 'money-stack',
  MoneyWithdrawal = 'money-withdrawal',
  Moneybag = 'moneybag',
  MonitorAlt_1 = 'monitor-alt-1',
  MonitorAlt_2 = 'monitor-alt-2',
  MonitorAlt_3 = 'monitor-alt-3',
  MonitorAlt_4 = 'monitor-alt-4',
  MonitorAlt_5 = 'monitor-alt-5',
  MonitorAlt_6 = 'monitor-alt-6',
  MonitorAlt = 'monitor-alt',
  MonitorHeartRate = 'monitor-heart-rate',
  MonitorMedical = 'monitor-medical',
  MonitorSun = 'monitor-sun',
  MonitorWater = 'monitor-water',
  Monitor = 'monitor',
  Monument = 'monument',
  MoonCloud = 'moon-cloud',
  MoonStars = 'moon-stars',
  Moon = 'moon',
  Moped = 'moped',
  Motorcycle = 'motorcycle',
  MountainCity = 'mountain-city',
  MouseAlt_1 = 'mouse-alt-1',
  MouseAlt_2 = 'mouse-alt-2',
  MouseAlt_3 = 'mouse-alt-3',
  MouseAlt_4 = 'mouse-alt-4',
  MouseAlt_5 = 'mouse-alt-5',
  MouseAlt = 'mouse-alt',
  Mouse = 'mouse',
  MoveAlt = 'move-alt',
  MoveLeft = 'move-left',
  MoveRight = 'move-right',
  Move = 'move',
  Mp3Player = 'mp3-player',
  MugSauser = 'mug-sauser',
  MusicNoteSlash = 'music-note-slash',
  MusicNote = 'music-note',
  Music = 'music',
  NetworkWired = 'network-wired',
  NfcSymbol = 'nfc-symbol',
  Notdef = 'notdef',
  NoteMedical = 'note-medical',
  NoteSticky = 'note-sticky',
  Notebook = 'notebook',
  Notes = 'notes',
  ObjectsAlignBottomAlt = 'objects-align-bottom-alt',
  ObjectsAlignBottom = 'objects-align-bottom',
  ObjectsAlignCenterHorizontalAlt = 'objects-align-center-horizontal-alt',
  ObjectsAlignCenterHorizontal = 'objects-align-center-horizontal',
  ObjectsAlignCenterVerticalAlt = 'objects-align-center-vertical-alt',
  ObjectsAlignCenterVertical = 'objects-align-center-vertical',
  ObjectsAlignLeftAlt = 'objects-align-left-alt',
  ObjectsAlignLeft = 'objects-align-left',
  ObjectsAlignRightAlt = 'objects-align-right-alt',
  ObjectsAlignRight = 'objects-align-right',
  ObjectsAlignTopAlt = 'objects-align-top-alt',
  ObjectsAlignTop = 'objects-align-top',
  ObjectsColumn = 'objects-column',
  OctagonExclamation = 'octagon-exclamation',
  Octagon = 'octagon',
  OilWell = 'oil-well',
  OrnamentAlt = 'ornament-alt',
  Ornament = 'ornament',
  Overline = 'overline',
  PaintRoller = 'paint-roller',
  PaintbrushAlt = 'paintbrush-alt',
  Paintbrush = 'paintbrush',
  Palette = 'palette',
  PaperclipAlt = 'paperclip-alt',
  PaperclipSlashAlt = 'paperclip-slash-alt',
  PaperclipSlash = 'paperclip-slash',
  Paperclip = 'paperclip',
  Paragraph = 'paragraph',
  PartyBell = 'party-bell',
  PartyHorn = 'party-horn',
  Pause = 'pause',
  PenCircle = 'pen-circle',
  PenClipSlash = 'pen-clip-slash',
  PenClip = 'pen-clip',
  PenField = 'pen-field',
  PenLine = 'pen-line',
  PenNibSlash = 'pen-nib-slash',
  PenNib = 'pen-nib',
  PenRuler = 'pen-ruler',
  PenSlash = 'pen-slash',
  PenSquare = 'pen-square',
  PenSwirl = 'pen-swirl',
  Pen = 'pen',
  PencilSlash = 'pencil-slash',
  Pencil = 'pencil',
  Pentagon = 'pentagon',
  Percent = 'percent',
  PhoneAlt = 'phone-alt',
  PhoneBlockAlt = 'phone-block-alt',
  PhoneBlock = 'phone-block',
  PhoneCallAlt_1 = 'phone-call-alt-1',
  PhoneCall = 'phone-call',
  PhoneFlipAlt = 'phone-flip-alt',
  PhoneFlip = 'phone-flip',
  PhoneIncomingAlt = 'phone-incoming-alt',
  PhoneIncoming = 'phone-incoming',
  PhoneIntercom = 'phone-intercom',
  PhoneLockAlt = 'phone-lock-alt',
  PhoneLock = 'phone-lock',
  PhoneMissedAlt = 'phone-missed-alt',
  PhoneMissed = 'phone-missed',
  PhoneOffice = 'phone-office',
  PhoneOutgoingAlt = 'phone-outgoing-alt',
  PhoneOutgoing = 'phone-outgoing',
  PhonePauseAlt = 'phone-pause-alt',
  PhonePause = 'phone-pause',
  PhonePlusAlt = 'phone-plus-alt',
  PhonePlus = 'phone-plus',
  PhoneSignalAlt = 'phone-signal-alt',
  PhoneSignal = 'phone-signal',
  PhoneSlashAlt = 'phone-slash-alt',
  PhoneSlash = 'phone-slash',
  PhoneXmarkAlt = 'phone-xmark-alt',
  PhoneXmark = 'phone-xmark',
  Phone = 'phone',
  Piano = 'piano',
  PlaceOfWorship = 'place-of-worship',
  PlayPause = 'play-pause',
  Play = 'play',
  Plug = 'plug',
  PlusLarge = 'plus-large',
  PlusMinus = 'plus-minus',
  Plus = 'plus',
  Podcast = 'podcast',
  Podium = 'podium',
  PollPeople = 'poll-people',
  PortraitUser = 'portrait-user',
  Potion = 'potion',
  PoundCircle = 'pound-circle',
  PoundSign = 'pound-sign',
  PowerAlt = 'power-alt',
  PowerBank = 'power-bank',
  PowerOff = 'power-off',
  PrescriptionBottleMedical = 'prescription-bottle-medical',
  PrescriptionBottle = 'prescription-bottle',
  PresentationChartAlt = 'presentation-chart-alt',
  PresentationChartArrow = 'presentation-chart-arrow',
  PresentationChart = 'presentation-chart',
  PresentationLine = 'presentation-line',
  PresentationScreen = 'presentation-screen',
  PrinterAlt = 'printer-alt',
  Printer = 'printer',
  Projector = 'projector',
  Question = 'question',
  Radiation = 'radiation',
  Radio = 'radio',
  Rainbow = 'rainbow',
  ReceiptAlt_1 = 'receipt-alt-1',
  ReceiptAlt_2 = 'receipt-alt-2',
  ReceiptAlt_3 = 'receipt-alt-3',
  ReceiptAlt = 'receipt-alt',
  Reciept = 'reciept',
  RectangleCode = 'rectangle-code',
  RectangleTerminal = 'rectangle-terminal',
  RectangleVertical = 'rectangle-vertical',
  RectangleWide = 'rectangle-wide',
  RectangleXmark = 'rectangle-xmark',
  RectanglesMixed = 'rectangles-mixed',
  RefreshCcwAlt_1 = 'refresh-ccw-alt-1',
  RefreshCcwAlt_2 = 'refresh-ccw-alt-2',
  RefreshCcwAlt_3 = 'refresh-ccw-alt-3',
  RefreshCcwAlt_4 = 'refresh-ccw-alt-4',
  RefreshCcwAlt = 'refresh-ccw-alt',
  RefreshCcwClock = 'refresh-ccw-clock',
  RefreshCcw = 'refresh-ccw',
  RefreshCwAlt_1 = 'refresh-cw-alt-1',
  RefreshCwAlt_2 = 'refresh-cw-alt-2',
  RefreshCwAlt_3 = 'refresh-cw-alt-3',
  RefreshCwAlt_4 = 'refresh-cw-alt-4',
  RefreshCwAlt = 'refresh-cw-alt',
  RefreshCw = 'refresh-cw',
  RepeatAlt = 'repeat-alt',
  Repeat = 'repeat',
  Reply = 'reply',
  ResizeHandle = 'resize-handle',
  RestaurantMenu = 'restaurant-menu',
  Rhombus = 'rhombus',
  RightIndent = 'right-indent',
  RightToLeftTextDirection = 'right-to-left-text-direction',
  Rocket = 'rocket',
  RockingHorse = 'rocking-horse',
  RollerCoaster = 'roller-coaster',
  RotateCcw = 'rotate-ccw',
  RotateCw = 'rotate-cw',
  RotateExclamation = 'rotate-exclamation',
  Rotate = 'rotate',
  Route = 'route',
  Rss = 'rss',
  RulerTraingle = 'ruler-traingle',
  Ruler = 'ruler',
  RupeeSign = 'rupee-sign',
  SantaClaus = 'santa-claus',
  SantaGlove = 'santa-glove',
  SantaHatAlt = 'santa-hat-alt',
  SantaHat = 'santa-hat',
  SatelliteDish = 'satellite-dish',
  ScaleBalanced = 'scale-balanced',
  ScaleUnbalancedFlip = 'scale-unbalanced-flip',
  ScaleUnbalanced = 'scale-unbalanced',
  Scan = 'scan',
  Scarecrow = 'scarecrow',
  SchoolFlag = 'school-flag',
  School = 'school',
  Scissors = 'scissors',
  ScreenUsers = 'screen-users',
  Screencast = 'screencast',
  Scribble = 'scribble',
  Scrubber = 'scrubber',
  Scythe = 'scythe',
  SealExclamation = 'seal-exclamation',
  SealQuestion = 'seal-question',
  Seal = 'seal',
  SearchAlt_1 = 'search-alt-1',
  SearchAlt_2 = 'search-alt-2',
  SearchAlt = 'search-alt',
  SearchMinus = 'search-minus',
  SearchPlus = 'search-plus',
  Search = 'search',
  SendAlt_1 = 'send-alt-1',
  SendAlt_2 = 'send-alt-2',
  SendAlt_3 = 'send-alt-3',
  SendAlt = 'send-alt',
  Send = 'send',
  SensorFire = 'sensor-fire',
  SensorOn = 'sensor-on',
  SensorTriangleExclamation = 'sensor-triangle-exclamation',
  Sensor = 'sensor',
  Server = 'server',
  Shapes = 'shapes',
  ShieldAlt_1 = 'shield-alt-1',
  ShieldAlt = 'shield-alt',
  ShieldBolt = 'shield-bolt',
  ShieldCheck = 'shield-check',
  ShieldDollar = 'shield-dollar',
  ShieldExclamation = 'shield-exclamation',
  ShieldHeart = 'shield-heart',
  ShieldKeyhole = 'shield-keyhole',
  ShieldMinus = 'shield-minus',
  ShieldPlus = 'shield-plus',
  ShieldSlashAlt_1 = 'shield-slash-alt-1',
  ShieldSlashAlt = 'shield-slash-alt',
  ShieldSlash = 'shield-slash',
  ShieldXmark = 'shield-xmark',
  Shield = 'shield',
  Shop = 'shop',
  Shredder = 'shredder',
  Shuffle = 'shuffle',
  SidebarFlip = 'sidebar-flip',
  Sidebar = 'sidebar',
  Sigma = 'sigma',
  SignalFair = 'signal-fair',
  SignalGood = 'signal-good',
  SignalSlash = 'signal-slash',
  SignalSteam = 'signal-steam',
  SignalStreamSlash = 'signal-stream-slash',
  SignalStrong = 'signal-strong',
  SignalWeak = 'signal-weak',
  Signal = 'signal',
  SignatureLock = 'signature-lock',
  Signature = 'signature',
  SignsPost = 'signs-post',
  SimcardAlt = 'simcard-alt',
  SimcardSlash = 'simcard-slash',
  Simcard = 'simcard',
  SipderWeb = 'sipder-web',
  Sitemap = 'sitemap',
  SkatesAlt = 'skates-alt',
  Skates = 'skates',
  SkullAlt_1 = 'skull-alt-1',
  SkullAlt = 'skull-alt',
  SkullCrossbones = 'skull-crossbones',
  Skull = 'skull',
  SlashFlip = 'slash-flip',
  Slash = 'slash',
  Sleigh = 'sleigh',
  SlidersSimple = 'sliders-simple',
  SlidersUp = 'sliders-up',
  Sliders = 'sliders',
  Snooze = 'snooze',
  SnowAlt_1 = 'snow-alt-1',
  SnowAlt = 'snow-alt',
  SnowBlowing = 'snow-blowing',
  Snow = 'snow',
  SnowmanHead = 'snowman-head',
  Snowman = 'snowman',
  SortAmountDown = 'sort-amount-down',
  SortAmountUp = 'sort-amount-up',
  SortDown = 'sort-down',
  SortUp = 'sort-up',
  Sort = 'sort',
  Sparkles = 'sparkles',
  SpeakerAlt_1 = 'speaker-alt-1',
  SpeakerAlt = 'speaker-alt',
  Speaker = 'speaker',
  SpellCheck = 'spell-check',
  SpiderWidow = 'spider-widow',
  Spider = 'spider',
  SquareALock = 'square-a-lock',
  SquareCheck = 'square-check',
  SquareCode = 'square-code',
  SquareCv = 'square-cv',
  SquareDashed = 'square-dashed',
  SquareDazzle = 'square-dazzle',
  SquareDollarChart = 'square-dollar-chart',
  SquareDollar = 'square-dollar',
  SquareDotsVertical = 'square-dots-vertical',
  SquareDots = 'square-dots',
  SquareExclamation = 'square-exclamation',
  SquareH = 'square-h',
  SquareKanban = 'square-kanban',
  SquareList = 'square-list',
  SquareMedical = 'square-medical',
  SquareMinus = 'square-minus',
  SquareParkingSlash = 'square-parking-slash',
  SquareParking = 'square-parking',
  SquarePen = 'square-pen',
  SquarePhoneFlip = 'square-phone-flip',
  SquarePhone = 'square-phone',
  SquarePollHorizontal = 'square-poll-horizontal',
  SquarePollVertical = 'square-poll-vertical',
  SquareQuarters = 'square-quarters',
  SquareRing = 'square-ring',
  SquareRss = 'square-rss',
  SquareSlidersVertical = 'square-sliders-vertical',
  SquareSliders = 'square-sliders',
  SquareSmall = 'square-small',
  SquareT = 'square-t',
  SquareTerminal = 'square-terminal',
  SquareUserChart = 'square-user-chart',
  SquareUserCheckAlt = 'square-user-check-alt',
  SquareUserCheck = 'square-user-check',
  SquareUser = 'square-user',
  Square = 'square',
  StaffSnake = 'staff-snake',
  StarAlt_1 = 'star-alt-1',
  StarAlt_2 = 'star-alt-2',
  StarAlt_3 = 'star-alt-3',
  StarAlt_4 = 'star-alt-4',
  StarExclamation = 'star-exclamation',
  StarHalfStroke = 'star-half-stroke',
  StarSharpHalf = 'star-sharp-half',
  StarSharp = 'star-sharp',
  Star = 'star',
  Stars = 'stars',
  Stocking = 'stocking',
  Stopwatch_10 = 'stopwatch-10',
  Stopwatch = 'stopwatch',
  Strikethrough = 'strikethrough',
  Subdirectory = 'subdirectory',
  Subscript = 'subscript',
  SuitcaseMedical = 'suitcase-medical',
  Suitcase = 'suitcase',
  SunAlt = 'sun-alt',
  SunDust = 'sun-dust',
  SunHaze = 'sun-haze',
  Sun = 'sun',
  Sunrise = 'sunrise',
  Sunset = 'sunset',
  Superscript = 'superscript',
  Swatchbook = 'swatchbook',
  SwitchHorizontal = 'switch-horizontal',
  SwitchVertical = 'switch-vertical',
  TableAlt = 'table-alt',
  TableCellsLarge = 'table-cells-large',
  TableCells = 'table-cells',
  TableColumns = 'table-columns',
  TableLayout = 'table-layout',
  TableListAlt = 'table-list-alt',
  TableList = 'table-list',
  TableRows = 'table-rows',
  TableTree = 'table-tree',
  Table = 'table',
  TabletAlt_1 = 'tablet-alt-1',
  TabletAlt_2 = 'tablet-alt-2',
  TabletAlt = 'tablet-alt',
  Tablet = 'tablet',
  Tag = 'tag',
  Tags = 'tags',
  Tally = 'tally',
  TeddyBear = 'teddy-bear',
  TemperatureArrowDown = 'temperature-arrow-down',
  TemperatureArrowUp = 'temperature-arrow-up',
  TemperatureEmpty = 'temperature-empty',
  TemperatureFull = 'temperature-full',
  TemperatureHalf = 'temperature-half',
  TemperatureHigh = 'temperature-high',
  TemperatureList = 'temperature-list',
  TemperatureLow = 'temperature-low',
  TemperatureQuarter = 'temperature-quarter',
  TemperatureSnow = 'temperature-snow',
  TemperatureSun = 'temperature-sun',
  Temple = 'temple',
  TengeSign = 'tenge-sign',
  TentArrowDownToLine = 'tent-arrow-down-to-line',
  TentArrowLeftRight = 'tent-arrow-left-right',
  TentArrowTurnLeft = 'tent-arrow-turn-left',
  TentArrowsDown = 'tent-arrows-down',
  Tent = 'tent',
  Tents = 'tents',
  Terminal = 'terminal',
  TextHeight = 'text-height',
  TextSize = 'text-size',
  TextWidth = 'text-width',
  Text = 'text',
  Thermometer = 'thermometer',
  Thumbtack = 'thumbtack',
  TicketSimple = 'ticket-simple',
  Timer = 'timer',
  ToiletPaperBlank = 'toilet-paper-blank',
  ToiletPaperDots = 'toilet-paper-dots',
  ToiletPortable = 'toilet-portable',
  ToiletsPortable = 'toilets-portable',
  TombstoneBlank = 'tombstone-blank',
  Tombstone = 'tombstone',
  ToriiGate = 'torii-gate',
  TowerBroadcastSlash = 'tower-broadcast-slash',
  TowerBroadcast = 'tower-broadcast',
  TowerObservation = 'tower-observation',
  Tower = 'tower',
  Trademark = 'trademark',
  TrafficCone = 'traffic-cone',
  TrafficLightGo = 'traffic-light-go',
  TrafficLightSlow = 'traffic-light-slow',
  TrafficLightStop = 'traffic-light-stop',
  TrafficLight = 'traffic-light',
  TrainSubway = 'train-subway',
  TrainTrack = 'train-track',
  TrainTram = 'train-tram',
  Train = 'train',
  TrashAlt = 'trash-alt',
  TrashArrowUpAlt = 'trash-arrow-up-alt',
  TrashArrowUp = 'trash-arrow-up',
  TrashBlankAlt = 'trash-blank-alt',
  TrashBlank = 'trash-blank',
  TrashCheckAlt = 'trash-check-alt',
  TrashCheck = 'trash-check',
  TrashClockAlt = 'trash-clock-alt',
  TrashClock = 'trash-clock',
  TrashListAlt = 'trash-list-alt',
  TrashList = 'trash-list',
  TrashPlusAlt = 'trash-plus-alt',
  TrashPlus = 'trash-plus',
  TrashSlashAlt = 'trash-slash-alt',
  TrashSlash = 'trash-slash',
  TrashUndoAlt = 'trash-undo-alt',
  TrashUndo = 'trash-undo',
  TrashXmarkAlt = 'trash-xmark-alt',
  TrashXmark = 'trash-xmark',
  Trash = 'trash',
  TreasureChest = 'treasure-chest',
  TreeDecoratedAlt_1 = 'tree-decorated-alt-1',
  TreeDecoratedAlt = 'tree-decorated-alt',
  TreeDecorated = 'tree-decorated',
  TreePlus = 'tree-plus',
  TriangleArrowsBolt = 'triangle-arrows-bolt',
  TriangleExclamation = 'triangle-exclamation',
  TriangleInstrument = 'triangle-instrument',
  Triangle = 'triangle',
  Trophy = 'trophy',
  Truck = 'truck',
  TughrikSign = 'tughrik-sign',
  TurkishLiraSign = 'turkish-lira-sign',
  TurnOff = 'turn-off',
  TvAlt_1 = 'tv-alt-1',
  TvAlt = 'tv-alt',
  Tv = 'tv',
  Umbrella = 'umbrella',
  Underline = 'underline',
  University = 'university',
  UnlockAlt = 'unlock-alt',
  Unlock = 'unlock',
  Upload = 'upload',
  UsbFlashDriveAlt_1 = 'usb-flash-drive-alt-1',
  UsbFlashDrive = 'usb-flash-drive',
  UserAlt_1 = 'user-alt-1',
  UserAlt = 'user-alt',
  UserBlockAlt_1 = 'user-block-alt-1',
  UserBlockAlt = 'user-block-alt',
  UserBlock = 'user-block',
  UserCheckAlt_1 = 'user-check-alt-1',
  UserCheckAlt = 'user-check-alt',
  UserCheck = 'user-check',
  UserHeartAlt_1 = 'user-heart-alt-1',
  UserHeartAlt = 'user-heart-alt',
  UserHeart = 'user-heart',
  UserLockAlt_1 = 'user-lock-alt-1',
  UserLockAlt = 'user-lock-alt',
  UserLock = 'user-lock',
  UserMinusAlt_1 = 'user-minus-alt-1',
  UserMinusAlt = 'user-minus-alt',
  UserMinus = 'user-minus',
  UserPenAlt_1 = 'user-pen-alt-1',
  UserPenAlt = 'user-pen-alt',
  UserPen = 'user-pen',
  UserPlusAlt_1 = 'user-plus-alt-1',
  UserPlusAlt = 'user-plus-alt',
  UserPlus = 'user-plus',
  UserQuestionAlt_1 = 'user-question-alt-1',
  UserQuestionAlt = 'user-question-alt',
  UserQuestion = 'user-question',
  UserRefreshAlt_1 = 'user-refresh-alt-1',
  UserRefreshAlt = 'user-refresh-alt',
  UserRefresh = 'user-refresh',
  UserSearchAlt_1 = 'user-search-alt-1',
  UserSearchAlt = 'user-search-alt',
  UserSearch = 'user-search',
  UserShieldAlt_1 = 'user-shield-alt-1',
  UserShieldAlt = 'user-shield-alt',
  UserShield = 'user-shield',
  UserXmarkAlt_1 = 'user-xmark-alt-1',
  UserXmarkAlt = 'user-xmark-alt',
  UserXmark = 'user-xmark',
  User = 'user',
  Users = 'users',
  Van = 'van',
  Vault = 'vault',
  Vial = 'vial',
  VideoArrowDownLeft = 'video-arrow-down-left',
  VideoArrowUpRight = 'video-arrow-up-right',
  VideoPlus = 'video-plus',
  VideoSlash = 'video-slash',
  Video = 'video',
  Vihara = 'vihara',
  VirusCovid_19 = 'virus-covid-19',
  Voicemail = 'voicemail',
  VolumeMax = 'volume-max',
  VolumeMin = 'volume-min',
  VolumeMinus = 'volume-minus',
  VolumeOff = 'volume-off',
  VolumePlus = 'volume-plus',
  VolumeXmark = 'volume-xmark',
  WalletAlt = 'wallet-alt',
  WalletArrowRight = 'wallet-arrow-right',
  WalletMinus = 'wallet-minus',
  WalletPlus = 'wallet-plus',
  Wallet = 'wallet',
  WandMagicSparkles = 'wand-magic-sparkles',
  WandMagic = 'wand-magic',
  WandSparkles = 'wand-sparkles',
  Wand = 'wand',
  WarehouseFull = 'warehouse-full',
  Warehouse = 'warehouse',
  WatchAlt_1 = 'watch-alt-1',
  WatchAlt_2 = 'watch-alt-2',
  WatchAlt = 'watch-alt',
  Watch = 'watch',
  WaterArrowDown = 'water-arrow-down',
  WaterArrowUp = 'water-arrow-up',
  Water = 'water',
  WavePulse = 'wave-pulse',
  WebcamAlt_1 = 'webcam-alt-1',
  WebcamAlt_2 = 'webcam-alt-2',
  WebcamAlt = 'webcam-alt',
  WebcamSlashAlt = 'webcam-slash-alt',
  WebcamSlash = 'webcam-slash',
  Webcam = 'webcam',
  WeightScale = 'weight-scale',
  Weight = 'weight',
  WifiExclamation = 'wifi-exclamation',
  WifiFair = 'wifi-fair',
  WifiGood = 'wifi-good',
  WifiSlash = 'wifi-slash',
  WifiWeak = 'wifi-weak',
  Wifi = 'wifi',
  WindWarning = 'wind-warning',
  Wind = 'wind',
  WindowFlip = 'window-flip',
  Window = 'window',
  WineGlass = 'wine-glass',
  WonSign = 'won-sign',
  Wreath = 'wreath',
  XmarkLarge = 'xmark-large',
  Xmark = 'xmark',
  YenSign = 'yen-sign',
}

type IconProps = {
  className?: string;
  name: IconName;
}

export const Icon = ( { name, className }: IconProps ) => {
  return (
    <i className={ classNames(
      `font-rewarble-icons rewarble-icons-${name}`,
      className
    ) }></i>
  )
}
